import { configureStore } from "@reduxjs/toolkit";
import appSettingReducer from "./appSetting";
import navigationReducer from "./navigation";
import loginReducer from "./login";
import cartReducer from "./cartWishlist";
import orderReducer from "./order";

export const store = configureStore({
  reducer: {
    appSettingData: appSettingReducer,
    navigationData: navigationReducer,
    loginUserData: loginReducer,
    cartWishlistData: cartReducer,
    orderData: orderReducer,
  },
});
