import React, { lazy, Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router";
import Loader from "../Components/Common/Loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "./ProtectedRoute";
const HomePage = lazy(() => import("../Components/Screens/HomePage"));
const SearchPage = lazy(() => import("../Components/Screens/SearchPage"));
const CategoryProducrPage = lazy(() =>
  import("../Components/Screens/CategoryProducrPage")
);
const ProductPage = lazy(() => import("../Components/Screens/ProductPage"));
const Cart = lazy(() => import("../Components/Screens/Cart"));
const Checkout = lazy(() => import("../Components/Screens/Checkout"));
const ConfirmationPage = lazy(() =>
  import("../Components/Screens/ConfirmationPage")
);
const PaymentFailedPage = lazy(() =>
  import("../Components/Screens/PaymentFailedPage")
);
const CheckoutPayment = lazy(() =>
  import("../Components/Screens/CheckoutPayment")
);
const Wishlist = lazy(() => import("../Components/Screens/Wishlist"));
const ContactUsPage = lazy(() => import("../Components/Screens/ContactUsPage"));
const JoinUsPage = lazy(() => import("../Components/Screens/JoinUsPage"));
const AboutUsPage = lazy(() => import("../Components/Screens/AboutUsPage"));
const FaqsPage = lazy(() => import("../Components/Screens/FaqsPage"));
const CustomerReviewsPage = lazy(() =>
  import("../Components/Screens/CustomerReviewsPage")
);
const AccountPage = lazy(() => import("../Components/Screens/AccountPage"));
const TermsAndConditionPage = lazy(() =>
  import("../Components/Screens/TermsAndConditionPage")
);
const PrivacyPolicyPage = lazy(() =>
  import("../Components/Screens/PrivacyPolicyPage")
);
const ReturnRefundPage = lazy(() =>
  import("../Components/Screens/ReturnRefundPage")
);
const ShippingPolicyPage = lazy(() =>
  import("../Components/Screens/ShippingPolicyPage")
);
const AccountOrdersPage = lazy(() =>
  import("../Components/Screens/AccountOrdersPage")
);
const AccountWalletPage = lazy(() =>
  import("../Components/Screens/AccountWalletPage")
);
const AccountAddressPage = lazy(() =>
  import("../Components/Screens/AccountAddressPage")
);
const AccountDetailsPage = lazy(() =>
  import("../Components/Screens/AccountDetailsPage")
);
const OrderTrackingPage = lazy(() =>
  import("../Components/Screens/OrderTrackingPage")
);
const OrderDetailsPage = lazy(() =>
  import("../Components/Screens/OrderDetailsPage")
);
const TrackingDetailPage = lazy(() =>
  import("../Components/Screens/TrackingDetailPage")
);
const AppRoutes = ({ token }) => {
  // const location = useLocation();
  // const dispatch = useDispatch();
  // const [authUser, setAuthUser] = useState(false);
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   dispatch(GetAppSettingData());
  // }, [location]);

  // const logoutUser = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("wishlistImages");
  //   localStorage.removeItem("lastSearchKeyword");
  //   setAuthUser(false);
  //   window.location.href = "/";
  // };
  // const token = localStorage?.getItem("token");
  // const user = JSON.parse(localStorage?.getItem("user"));

  // useEffect(() => {
  //   if (token && user) {
  //     const decodedJwt = jwt_decode(token);
  //     setAuthUser(true);
  //     const expirationTime = decodedJwt?.exp * 1000;
  //     if (expirationTime < Date.now()) {
  //       logoutUser();
  //     }
  //   }
  // }, [location, token, user]);
  // useEffect(() => {
  //   if (token && user) {
  //     if (
  //       user?.company_name === null ||
  //       user?.country === null ||
  //       user?.email === null ||
  //       user?.first_name === null ||
  //       user?.last_name === null ||
  //       user?.mobile === null ||
  //       user?.job_description === null ||
  //       user?.state === null
  //     ) {
  //       setOpenFreeImageForm(true);
  //     }
  //   }
  // }, [location, token, user]);
  return (
    <Suspense fallback={<Loader />}>
      {/* {openFreeImageForm === true && (
        <AfterSocialLoginForm
          open={openFreeImageForm}
          onClose={handleCloseUserDetailsForm}
        />
      )} */}
      <ToastContainer />
      <Toaster
        position="bottom-center"
        toastOptions={{
          duration: 2500,
          style: {
            borderRadius: "1.5rem",
            background: "#333",
            padding: "0.5rem 0.8rem 0.5rem 2rem",
            color: "#fff",
          },
        }}
      />
      <Routes>
        {/* without login  root */}
        <Route path="/" element={<HomePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/clothing" element={<CategoryProducrPage />}>
          <Route path=":searchKeyword" element={<CategoryProducrPage />} />
        </Route>

        <Route path="/product/:productId" element={<ProductPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/joinus" element={<JoinUsPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/customer-review" element={<CustomerReviewsPage />} />
        <Route path="/termsandcondition" element={<TermsAndConditionPage />} />
        <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />
        <Route path="/return-exchange-policy" element={<ReturnRefundPage />} />
        <Route path="/shippingpolicy" element={<ShippingPolicyPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/faqs" element={<FaqsPage />} />
        {/* Protected Routes */}
        <Route element={<ProtectedRoute isAllowed={!!token} />}>
          <Route path="/orderdetail/:orderId" element={<OrderDetailsPage />} />
          <Route path="/trackingdetail" element={<TrackingDetailPage />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/tracking" element={<OrderTrackingPage />} />
          <Route path="/account/details" element={<AccountDetailsPage />} />
          <Route path="/account/wallet" element={<AccountWalletPage />} />
          <Route path="/account/orders" element={<AccountOrdersPage />} />
          <Route path="/account/address" element={<AccountAddressPage />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/confirm/:orderId" element={<ConfirmationPage />} />
          <Route path="/failed/:orderId" element={<PaymentFailedPage />} />
          <Route path="/checkout/payment" element={<CheckoutPayment />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
