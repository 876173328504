import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    promotionBar: {
      backgroundColor: "#262626",
      primaryFontColor: "white",
      linkColor: "blue",
    },
    header: {
      backgroundColor: "white",
      primaryFontColor: "black",
      subMenuFontColor: "black",
      productTitleFontColor: "black",
      productSubTitleFontColor: "#757575",
      productMainPriceFontColor: "#757575",
      productSalePriceFontColor: "black",
      productSaleFontColor: "red",
      contrastFontColor: "#757575",
      subChildMenuFontColor: "black",
      linkColor: "red",
      borderColor: "red",
      hoverPrimaryFontColor: "red",
      categoryMenuBackgroundColor: "#f0f4f7",
      categoryMenuColor: "black",
      headerActionButton: "#268cb9",
      categoryMenuContentBackgroundColor: "white",
    },
    search: {
      defaultSearchSuggestionTitleColor: "red",
      defaultSearchSuggestionColor: "black",
      searchBarBgColor: "rgba(71, 71, 71, 0.97)",
      searchBarInputBgColor: "white",
      searchBarInputTextColor: "black",
    },
    productDetail: {
      productTitleFontColor: "black",
      productSubTitleFontColor: "#757575",
      productMainPriceFontColor: "#757575",
      productSalePriceFontColor: "black",
      productSaleFontColor: "red",
    },
    productPage: {
      offerTextColor: "green",
      mainTextColor: "black",
      lightTextColor: "#777777",
      secondaryTextColor: "#242424",
      highlightTextColor: "#ff7600",
      imageBackgroundColor: "#e3e3e3",
      mobileButtonBGColor: "white",
    },
    bottomNavigation: {
      backgroundColor: "white",
      primaryFontColor: "black",
    },
    sizeSelect: {
      lightTextColor: "#268cb9",
      borderColor: "#b4b4b4",
      selectedBorderColor: "red",
      selectedBgColor: "rgba(255,156,156,0.1)",
      selectedTextColor: "red",
      textColor: "black",
      impBgColor: "black",
      imgSelectedBgColor: "red",
      impTextColor: "white",
    },
    sizeChart: {
      buttonBgColor: "white",
      buttonTextColor: "black",
      selectedButtonBgColor: "#242424",
      selectedButtonTextColor: "white",
      tableBgColor: "white",
      tableBorderColor: "#d2d2d294",
      evenRowBgColor: "#f0eeee",
      oddRowBgColor: "white",
      textColor: "#242424",
      thirdColor: "white",
      descriptionBorderColor: "orange",
    },
    footer: {
      backgroundColor: "#151515",
      primaryFontColor: "white",
      mobileSectionBgColor: "#363636",
      socialTextColor: "white",
      socialBgColor: "#4b4b4b",
      newsletterBgColor: "#363636",
      newsletterTextColor: "white",
    },
    categoryPage: {
      imageBackgroundColor: "#e3e3e3",
      popularCategoryBackgroundColor: "white",
      selectedPopularCategoryBGColor: "rgb(255 243 226)",
      selectedPCCheckBGColor: "rgb(255 149 0)",
      mobFilterGroupBGColor: "#f0f4f7",
      mobFilterGroupTextColor: "#212121",
      mobSelectedFilterBgColor: "white",
      mobFilterOptionsBgColor: "white",
      mobFilterButtonBgColor: "white",
      pCCheckBGColor: "#e4e4e4",
    },
    stepper: {
      textColor: "#242424",
      highlightColor: "#4C49B9",
      mainTextColor: "black",
      activeStepperBgColor: "#4C49B9",
      stepperBgColor: "#bebebe",
    },
    aboutUs: {
      locationIconBgColor: "#ddddff",
      backgroundColor: "#f0f4f7",
      textColor: "#9e9dd1",
      locationTextColor: "#242424",
      locationSubTextColor: "#8a8a8a",
      highlightColor: "#4c49b9",
      secondaryHightlightColor: "orange",
    },
    reviewPage: {
      backgroundColor: "white",
      secondaryHightlightColor: "orange",
      primaryTextColor: "black",
    },
    account: {
      layoutBGColor: "#f0f4f7",
      accountLayoutBGColor: "white",
      profileBgColor: "black",
      textColor: "white",
      secondaryTextColor: "#242424",
      lighttextColor: "#666666",
      accountMenuColor: "#242424",
      order: {
        textColor: "#242424",
        orderTagBGColor: "#f0f4f7",
        lightTextColor: "#777777",
      },
      menuButton: {
        textColor: "#242424",
        bgColor: "white",
      },
      wallet: {
        contentBGColor: "#F5F8FC",
        mainTextColor: "black",
        lightTextColor: "#777777",
        creditBGColor: "#e9ffe7",
        debitBGColor: "#ffe9e9",
        creditIconBGColor: "#73c273",
        debitIconBGColor: "#c27373",
      },
    },
    address: {
      mainTextColor: "black",
      secondaryTextColor: "#7e7e7e",
      layoutBGColor: "#f0f4f7",
      primaryChipBgColor: "#ededed",
      secondaryChipBgColor: "#6c6c6c",
      secondaryChipTextColor: "white",
      mainChipBgColor: "#242424",
      cardBgColor: "white",
    },
    policy: {
      mainTextColor: "#242424",
      secondTextColor: "rgba(0, 0, 0, 0.87)",
    },
    link: {
      textColor: "#268cb9",
    },
    sliderColor: {
      titleColor: "black",
      imageTitleColor: "white",
      imageBackgroundColor: "#e3e3e3",
      imageSubTitleColor: "white",
    },
    promotionalColor: {
      freeColor: "#0bb775",
    },
    cartColor: {
      cartCardColor: "#fff",
      cartProductTitle: "#242424",
      mainTitle: "#242424",
      cartPrice: "",
      layoutBGColor: "#f0f4f7",
      desktopBannerBgColor: "rgb(15, 67, 56)",
      desktopBannerTexColor: "white",
      applyCopounBGColor: "white",
      cartMainTextColor: "black",
      lightTextColor: "#777777",
      mobileButtonBGColor: "white",
      keyFeatureBgColor: "#e4e4e4",
      dataCardBgColor: "white",
      offerBorderColor: "rgba(6, 135, 67, 0.28)",
      offerBgColor: "rgba(6, 135, 67, 0.08)",
    },
    coupon: {
      mainTextColor: "black",
      lightTextColor: "#777777",
      secondTextColor: "#242424",
    },
    checkout: {
      layoutBGColor: "#f0f4f7",
      mobileButtonBGColor: "white",
      mainTextColor: "black",
      payment: {
        lightTextColor: "#777777",
        contrastTextColor: "white",
        coinBgColor: "#8b8b8b",
        layoutBGColor: "#f0f4f7",
        paymentCardBgColor: "#f0f4f7",
        selectedPaymentBgColor: "#ededed",
        paymentBgColor: "white",
        paymentTextColor: "#242424",
        selectedPaymentBorderColor: "#242424",
        paymentaymentBorderColor: "#dbdbdb",
        mobileButtonBGColor: "white",
        mainTextColor: "black",
        mainTitle: "#242424",
        desktopBannerBgColor: "rgb(15, 67, 56)",
        desktopBannerTextColor: "white",
      },
      mainTitle: "#242424",
      desktopBannerBgColor: "rgb(15, 67, 56)",
      desktopBannerTextColor: "white",
    },
    contact: {
      textColor: "#7a7a7a",
      highlightColor: "#333",
      shareIconbgColor: "#ddddff",
      mainTextColor: "#242424",
      lightTextColor: "#8a8a8a",
      socialIconBgColor: "#4c49b9",
    },
    login: {
      mainTextColor: "black",
      lightTextColor: "#6f6f6f",
      disableLinkColor: "gray",
      linkColor: "blue",
    },
    join: {
      textColor: "#7a7a7a",
      highlightColor: "#333",
    },
    order: {
      mainTextColor: "black",
      secondaryTextColor: "#242424",
      lightTextColor: "#747474",
      layoutBGColor: "#f0f4f7",
      mainTitle: "#242424",
      desktopBannerBgColor: "rgb(15, 67, 56)",
      desktopBannerTextColor: "white",
      keyFeatureBgColor: "#e4e4e4",
      mobileButtonBGColor: "white",
    },
    trackInfo: {
      textColor: "#7a7a7a",
      highlightColor: "#333",
      trackingDetail: {
        layoutBGColor: "#f0f4f7",
      },
    },
    wishlist: {
      imageBgColor: "#cdcdcd",
      desktopBannerBgColor: "rgb(15, 67, 56)",
      desktopBannerTextColor: "white",
      mainTitle: "#242424",
      layoutBGColor: "#f0f4f7",
    },
  },
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "none",
          textTransform: "none",
          minWidth: "auto",
          maxWidth: "100%",
          "&.commonButtonStyles": {
            fontWeight: 600,
            color: "#FFFFFF",
            backgroundColor: "#272727",
            border: "1px solid #272727",
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "gray",
              border: "1px solid gray",
            },
          },
          "&.cartButtonStyles": {
            fontWeight: 600,
            color: "blue",
            backgroundColor: "white",
            border: "1px solid blue",
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "gray",
              border: "1px solid gray",
            },
          },
          "&.darkButtonStyles": {
            color: "#FFFFFF",
            fontWeight: 600,
            backgroundColor: "black",
            border: "1px solid black",
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "gray",
              border: "1px solid gray",
            },
          },
          "&.IconButtonStyles": {
            color: "#FFFFFF",
            backgroundColor: "#f3f3f3",
            border: "1px solid #f3f3f3",
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "gray",
              border: "1px solid gray",
            },
            "&.hasBorder": {
              border: "1px solid #272727",
            },
          },
          "&.commonOutlineButtonStyles": {
            fontWeight: 600,
            color: "#272727",
            backgroundColor: "white",
            border: "1px solid #272727",
            "&:disabled": {
              color: "#FFFFFF",
              backgroundColor: "gray",
              border: "1px solid gray",
            },
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        // Name of the slot
        paper: {
          width: "100%",
          overflow: "hidden",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "input:disabled": {
            cursor: "not-allowed",
          },
          ".Mui-disabled": {
            cursor: "not-allowed",
          },
        },
      },
    },
    MuiRating: {
      styleOverrides: {
        root: {
          color: "black",
          "&.SecondColorRating": {
            color: "#ff703e",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&.BaseIconButton": {
            fontSize: "1.375rem",
            borderRadius: "50%",
            padding: "2px",
            margin: "0 0 0 20px",
            backgroundColor: "transparent",
            "&:hover": {
              // backgroundColor: "black",
              opacity: "50%",
            },
          },
          "&.PrimaryIconButton": {
            borderRadius: "50%",
            padding: "8px",
            backgroundColor: "black",
            "&:hover": {
              // backgroundColor: "black",
              opacity: "50%",
            },
          },
          "&.SmallIconButton": {
            borderRadius: "50%",
            padding: "5px",
            backgroundColor: "black",
            "&:hover": {
              // backgroundColor: "black",
              opacity: "50%",
            },
          },
        },
        // Name of the slot
      },
    },
    MuiBadge: {
      styleOverrides: {
        // Name of the slot
        badge: {
          borderRadius: "50%",
          backgroundColor: "#5d5d5d",
          color: "white",
          right: "-10%",
          top: "-14%",
          fontSize: "0.813rem",
        },
      },
    },
  },

  typography: {
    // mainpagetitle: {
    //   fontFamily: "Poppins",
    //   fontStyle: "normal",
    //   fontSize: "17px",
    //   lineHeight: "22px",
    //   "@media only screen and (min-width: 280px) and (max-width: 749px)": {
    //     fontSize: "15px",
    //     lineHeight: "20px",
    //   },
    //   "&.primaryColor": {
    //     color: "black",
    //   },
    // },
    largeTitleFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "1.375rem",
      lineHeight: "25px",
      "@media only screen and (min-width: 280px) and (max-width: 1023px)": {
        fontSize: "1rem",
        lineHeight: "20px",
      },
    },
    largeTitleFontWRespo: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "1.375rem",
      lineHeight: "25px",
    },
    pageTitleFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "1.063rem",
      lineHeight: "22px",
    },
    pageMainDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.938rem",
      lineHeight: "18px",
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
    },
    pageSubDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "1rem",
      lineHeight: "20px",
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
      "@media only screen and (min-width: 280px) and (max-width: 1023px)": {
        fontSize: "0.875rem",
        lineHeight: "18px",
      },
      "&.text-lineThrouugh": {
        textDecoration: "line-through",
      },
    },
    sectionTitleFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.875rem",
      lineHeight: "18px",
      "&.text-capitalize": {
        textTransform: "capitalize",
      },
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
      "@media only screen and (min-width: 280px) and (max-width: 1023px)": {
        fontSize: "0.75rem",
        lineHeight: "16px",
      },
    },
    sectionMainDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.813rem",
      lineHeight: "16px",
      "&.text-capitalize": {
        textTransform: "capitalize",
      },
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
      "&.text-lineThrouugh": {
        textDecoration: "line-through",
      },
    },
    sectionDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.75rem",
      lineHeight: "16px",
      "&.text-capitalize": {
        textTransform: "capitalize",
      },
      "&.text-lineThrouugh": {
        textDecoration: "line-through",
      },
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
    },
    sectionSubDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.625rem",
      lineHeight: "14px",
      "&.text-capitalize": {
        textTransform: "capitalize",
      },
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
    },
    sectionMicroDataFont: {
      fontFamily: "Poppins",
      fontStyle: "normal",
      fontSize: "0.563rem",
      lineHeight: "11px",
      "&.text-capitalize": {
        textTransform: "capitalize",
      },
      "&.text-uppercase": {
        textTransform: "uppercase",
      },
    },
  },
});
