const apiUrl = process.env.REACT_APP_API_URL;
const baseUrl = apiUrl;
const PREPAID_DISCOUNT_PERCENTAGE =
  process.env.REACT_APP_PREPAID_DISCOUNT_PERCENTAGE;
const BASE_URL_MAIN = `${baseUrl}`;
const LIVE_WEBSITE_URL = process.env.REACT_APP_LIVE_WEBSITE_URL;
const REACT_APP_SALT = process.env.REACT_APP_SALT;

let ApiConfig = {
  REACT_APP_SALT,
  LIVE_WEBSITE_URL,
  BASE_URL_MAIN,
  PREPAID_DISCOUNT_PERCENTAGE,
  //login api
  login: `${BASE_URL_MAIN}users/auth/login`,
  verifyOtp: `${BASE_URL_MAIN}users/auth/verify-otp`,
  getProfileDetails: `${BASE_URL_MAIN}users/get-profile`,
  updateProfileDetails: `${BASE_URL_MAIN}users/update-profile`,
  commonSearchApi: `${BASE_URL_MAIN}common/product/get`,
  commonAddToCart: `${BASE_URL_MAIN}common/cart/add-to-cart`,
  commonUpdateToCart: `${BASE_URL_MAIN}common/cart/update-cart`,
  commonRemoveFromCart: `${BASE_URL_MAIN}common/cart/remove-from-cart`,
  commonAddToWishlist: `${BASE_URL_MAIN}common/wishlist`,
  commonApplyCoupon: `${BASE_URL_MAIN}common/coupon/apply`,
  commonAddAddress: `${BASE_URL_MAIN}common/address/add`,
  commonEditAddress: `${BASE_URL_MAIN}common/address/edit`,
  commonDeleteAddress: `${BASE_URL_MAIN}common/address/delete`,
  getAddressByPincode: `${BASE_URL_MAIN}common/address/pin-code`,
  addAddressToCart: `${BASE_URL_MAIN}users/order/update`,
  createNewOrder: `${BASE_URL_MAIN}users/order/create`,
  getAllWishlist: `${BASE_URL_MAIN}common/wishlist/get-wishlist-product`,
  getAllCouponList: `${BASE_URL_MAIN}common/coupon/get`,
  getAllAddressList: `${BASE_URL_MAIN}common/address/list`,
  getSingleOrderList: `${BASE_URL_MAIN}users/order/get`,
  getAllOrdersList: `${BASE_URL_MAIN}users/order/list`,
  getAllCartList: `${BASE_URL_MAIN}common/cart/get-cart`,
  getAppSetting: `${BASE_URL_MAIN}common/cms/get`,
};

export default ApiConfig;
